<template>
  <header class="navbar header">
    <section class="navbar-section">
      <span class="navbar-brand">AED Map</span>
    </section>
    <section class="navbar-section">
      <label class="form-switch">
        <input type="checkbox" v-model="trace">
        <i class="form-icon"></i>Sync
      </label>
    </section>
  </header>
  <div class="mapPane">
    <l-map
      ref="map"
      :zoom="zoom"
      :center="center"
      :options="{attributionControl:false}"
    >
      <l-control-layers
          position="topright"
      ></l-control-layers>
      <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"
      ></l-tile-layer>
      <l-layer-group ref="features">
        <l-marker
          :lat-lng="marker"
          ref="pointer"
        >
          <l-icon
            :iconSize="[32,32]"
            icon-url="images/street-view-icon-5.jpg">
          </l-icon>
        </l-marker>
        <l-circle
          :lat-lng="marker"
          :radius="30"
          :fill="true"
          :stroke="false"
          fillColor="blue"
          :fillOpacity="0.5"
        />
        <l-circle
          :lat-lng="marker"
          :radius="150"
          :fill="true"
          :stroke="false"
          fillColor="blue"
          :fillOpacity="0.2"
        />
        <l-circle
          :lat-lng="marker"
          :radius="300"
          :fill="true"
          :stroke="false"
          fillColor="blue"
          :fillOpacity="0.1"
        />
      </l-layer-group>
      <l-marker
        v-for="item in list"
        :key="item.id"
        :lat-lng="item.latlng"
      >
        <l-icon
          :iconSize="[32,32]"
          icon-url="images/aed.jpg">
        </l-icon>
        <l-popup
          :options="{autoPan:false, autoClose: false, keepInView: true}"
        >
          {{item.buildingName}}<br />{{item.areaName}}<br />{{item.distance}}m ({{item.DIST}})
        </l-popup>
      </l-marker>

    </l-map>
    <div class="footer">
      <div class="chip">
        <!--{{nearest.buildingName}}/{{nearest.areaName}} {{nearest.distance}} m-->
        {{marker[0]}},{{marker[1]}}
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, reactive, watch, onMounted} from 'vue';
import {
    LMap,
    LTileLayer,
    LControlLayers,
    LLayerGroup,
    LMarker,
    LIcon,
    LPopup,
    LCircle,
} from '@vue-leaflet/vue-leaflet';
import aed from '@/api/aed';

const map = ref(null);

const trace = ref(false);

const load = ref(false);

const zoom = ref(17);

const center = ref([0,0]);

const marker = ref([0,0]);

const list = ref([]);

const nearest = ref({});

const tileProviders = reactive([
  {
    name: 'Open Street Map',
    visible: true,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '© <a href="http://osm.org/copyright">OpenStreetMap</a>'
      + ' contributors,'
      +' <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
  }
]);

const calcDistance = (lat1,lng1,lat2,lng2) => {
  let km = 6371 * Math.acos(
    Math.cos(lat1/180*Math.PI) *
    Math.cos((lng2 - lng1)/180*Math.PI) *
    Math.cos(lat2/180*Math.PI) +
    Math.sin(lat1/180*Math.PI) *
    Math.sin(lat2/180*Math.PI)
  );
  return Number((km * 1000).toFixed(2));
};

const refresh = position => {
  marker.value = [
    position.coords.latitude,
    position.coords.longitude
  ];
  center.value = [
    position.coords.latitude,
    position.coords.longitude
  ];
  (async () => {
    const res = await aed.get(
      position.coords.latitude, position.coords.longitude, 300);

    //console.log(res);
    list.value = res.data.map( e => {
      const distance = calcDistance(
        position.coords.latitude,
        position.coords.longitude,
        e.Latitude,
        e.Longitude
      );
      return {
        uuid: e.Id,
        buildingName: e.LocationName,
        areaName: e.FacilityPlace,
        latlng:[e.Latitude ,e.Longitude],
        distance: distance,
        DIST: e.DIST,
      };
    });
    if (list.value.length === 0) {
      nearest.value = {};
    } else {
      nearest.value = list.value.reduce((a,b)=> a.distance < b.distance?a:b);
    }
  })();

};

setTimeout( () => {
  map.value.leafletObject.on('click', e => {
    if (!trace.value) {
      refresh({coords:{latitude: e.latlng.lat, longitude: e.latlng.lng}});
    }
  });

  window.navigator.geolocation.getCurrentPosition( postion => {
    refresh(postion);
  }, error => {
    console.error(error);
  },{
    enableHighAccuracy:true,
  });
}, 1000);

const watchPos = () => {
  return window.navigator.geolocation.watchPosition(
    postion => {
      refresh(postion);
    },
    error => {
      console.error(error);
    },
    {
      enableHighAccuracy:true,
    }
  );
};


let id;
if (trace.value) {
  id = watchPos();
}
watch(trace, (val) => {
  if (val) {
    id = watchPos();
  } else {
    window.navigator.geolocation.clearWatch(id);
  }
});

</script>

<style scoped>
.header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0 5px;
}
.mapPane {
    height: calc(100vh - 50px);
    margin: 0;
    text-align: left;
}
.footer {
  position:fixed;
  bottom: 0;
  left:0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  background-color: white;
  padding: 0 5px;
}
</style>
