const request = async function(options) {
  const cordova = window.cordova;
  if (cordova) {
    return new Promise((resolve, reject) => {
      cordova.plugin.http.sendRequest(
        options.url,
        options,
        response => {
          response.data = JSON.parse(response.data);
          resolve(response);
        },
        response => {
          reject(response);
        }
      );
    });
  } else {
    let response;
    response = await fetch(
      options.url,
      options
    );
    response.data = await response.json();
    return response;
  }
};

const get = async function( url, options ) {
  return request({url, method: 'get', ...options});
};

const post = async function( url, options ) {
  return await request({url, method: 'post', ...options});
};

const put = async function( url, options ) {
  return await request({url, method: 'put', ...options});
};

const _delete = async function( url, options ) {
  return await request({url, method: 'delete', ...options});
};


export default {
  request,
  get,
  post,
  put,
  delete: _delete
};
