<template>
  <MapPane v-if="deviceReady" />
</template>

<script setup>
import {ref} from 'vue';
import MapPane from '@/components/MapPane.vue';
/**
 * 初期起動
 */
const deviceReady = ref(false);
if (typeof window.cordova === 'undefined') {
  deviceReady.value = true;
} else {
  //window.setTimeout(() => {
    document.addEventListener('deviceready', async () => {
      deviceReady.value = true;
    }, false);
  //}, 1000);
}
</script>

<style>
body {
  margin: 0;
}
</style>
