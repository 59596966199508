import http from '@/utils/wrapper/CordovaAdvancedHttp';

const BASE_URL = 'https://aed.azure-mobile.net';
//const BASE_URL = '';
//http://hatsunejournal.azurewebsites.net/w8/AEDOpendata/
export default  {
  async get(lat,lng,r) {
    const options = {
      mode: 'cors',
    };
    return await http.get(
      `${BASE_URL}/api/AEDSearch?lat=${lat}&lng=${lng}&r=${r}`, options);
  }
};
